.distance {
    font-family: 'Montserrat', sans-serif;
    color: #272727;
    display: flex;
    align-items: center;

    &.w-full {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        padding: 0 15px;
        margin-bottom: 23px;
        margin-top: 4px;
        display: flex;
        justify-content: space-between;

        &.distance-0 {
            margin-bottom: 26px;
            margin-top: 0;
        }

        i {
            margin-right: 5px;
        }

        &.not-found {
            margin-bottom: 0;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &.placeholder {
        flex: 0 1 auto;

        .count {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            //white-space: nowrap;
        }

        i {
            width: 11px;
            height: 11px;
            margin-left: 10px;
        }
    }
    .distance-count {
        display: flex;
    }
    .distance-page {
        color: #acaaaa;
        font-weight: 400;
    }
}
.or {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}
