.main.page.user-panel.reviews {
    margin-top: 0;
    .review-item {
        display: flex;
        flex-wrap: wrap;

        .block-header {
            margin-left: 0;
        }

        .photo {
            img {
                max-height: 200px;
                border-radius: 4px;
            }
        }
        .review-text {
            padding-left: 10px;
            .title {

            }
            .date {
                font-size: 14px;
                padding-left: 20px;
            }

            .message, .actions {
                padding-left: 10px;
            }
            .actions {
                margin-top: 10px;
                ul {
                    display: flex;
                    gap: 10px;
                }
            }
        }

        .review-row {
            font-size: 15px;
            .review-left {
                width: 141px;
                font-weight: 500;
            }
            .review-right {
                width: initial;
            }
            .review-rating-star {
                width: 18px;
                height: 22px;
            }
        }
    }
}
