.footer {
    background: #3d3d3d;
    color: #E7E7E7;
    text-align: left;
    width: 100%;
    min-width: 320px;
    -ms-flex-order: 3;
    order: 3;
    padding: 20px 0;

    .footer-text {
        font-size: 1.2rem;
        line-height: 2.4rem;
        font-family: Montserrat, sans-serif;
    }
    .footer-config {
        a.enable {
            color: #1fff10;
        }
    }

    a {
        color: #f77878;
    }

}

.seo-footer {
    padding: 25px 0px 15px 0px;
    background: #F0F0F0;

    .section-catalog__text {
        margin-bottom: 0;
    }

    #more {
        margin-bottom: 0;
    }
}
