.breadcrumbs {
    display: flex;
    align-items: center;

    i {
        width: 6px;
        height: 9px;
        margin: 0 9px;
        background-color: #AAAAAA;
    }
    a:not(:first-child) {
        //padding-left: 8px;
        //&:before {
        //    content: "";
        //    background: url(../svg/icon-dropdown-filter.svg) no-repeat;
        //    width: 17px;
        //    height: 9px;
        //    display: inline-block;
        //}
    }
    @include media-breakpoint-down(md) {
        .wrapper {
            overflow-x: auto;
            padding: 0
        }
    }
    @include media-breakpoint-down(sm) {
        & {
            display: none;
        }
    }
}
.breadcrumbs__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 16px 0;
    height: 40px;

    li {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        font-size: 1.6rem;
        line-height: 2rem;
        color: #900C3F;
        font-weight: 300;
        white-space: nowrap;

        &:before {
            content: "";
            display: block;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            margin-top: 7px;
            margin-left: 8px;
            margin-right: 8px;
            width: 5px;
            height: 10px;
            background-image: url(../svg/icon-breadcrumb.svg);
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: .7;
        }

        &:first-child:before {
            display: none
        }

        &:last-child:before {
            opacity: 1
        }
    }

    a {
        color: #272727;
        white-space: nowrap;

        &:hover {
            text-decoration: underline
        }
    }

    @include media-breakpoint-down(md) {
        & {
            padding: 10px 20px;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            width: auto;
            padding-right: 60px
        }

        li {
            &:last-child {
                padding-right: 80px
            }
            &:before {
                margin-top: 6px
            }
        }
    }
}

.breadcrumbs {
    margin: 18px 0 0 0 ;
}
.breadcrumbs,
.product-activity.on-mobile {
    font-size: 14px;
}
.breadcrumbs a,
.product-activity.on-mobile a{
    color: #AAAAAA;
}
.breadcrumbs a:hover,
.product-activity.on-mobile a:hover {
    color: #900C3F;
    text-decoration: none;
}
.product-activity.on-mobile i {
    width: 6px;
    height: 9px;
    margin: 0 9px;
    background-color: #AAAAAA;
}

.product-activity,
.product-agency-row,
.breadcrumbs {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #AAAAAA;
    margin-bottom: 20px;
}

.statistics {
    display: flex;
    align-items: baseline;
    padding-bottom: 4px;
}

.statistics-mob, .breadcrumbs-mob {
    display: none;
}

.statistics-text {
    padding-left: 30px;
    font-weight: 600;
    font-size: 2rem;
}

.statistics-icon {
    height: 16px;
    width: 3px;
    border-radius: 50px;
    background-color: #900C3F;
    position: relative;
    top: 0;
    left: 20px;
    cursor: pointer;
}

.statistics-icon-2 {
    position: absolute;
    height: 4px;
    width: 3px;
    border-radius: 50px;
    background-color: #900C3F;
    bottom: 0;
    left: -15px;
    cursor: pointer;
}

.statistics-icon::before, .statistics-icon::after {
    content: "";
    position: absolute;
    height: inherit;
    border-radius: inherit;
    background-color: inherit;
    margin: auto;
    left: 20px;
    bottom: 0;
    width: 100%;
    transition: all ease 0.5s;
    cursor: pointer;
}

.statistics-icon::before{
    left: -5px;
    height: 75%;
}

.statistics-icon::after {
    right: 10px;
    left: auto;
    height: 50%;
}

@include media-breakpoint-down(md) {
    .page-catalog__head .statistics {
        position: relative;
        top: 4px;
        margin: 0 -20px 0;
        padding: 10px 20px;
        background-color: rgba(39, 39, 39, .05);
        align-items: baseline;
        height: 37px;
    }

    .statistics {
        position: relative;
        top: 4px;
    }

    .statistics-mob {
        display: flex;
    }

    .statistics-desk {
        display: none;
    }

    .breadcrumbs-mob {
        display: block;
    }
}
