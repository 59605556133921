.modal.modal-confirm {
    .modal-content-wrap {
        .modal-title {
            text-align: center;

            &:before {
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
