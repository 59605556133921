.main.page.user-panel.favorite {
    .favorite-item {
        display: flex;
        align-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        .product-favorite {
            padding: 10px;
            margin-right: 0;
        }

        a.main-button {
            margin: 0;
        }
    }
}
