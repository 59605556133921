.catalog-item {
    .img-wrapper {
        cursor: pointer;
    }
    &.item-salon {
        .catalog-item__details {
            height: 67px;
            .catalog-item__checked-container {
                justify-content: flex-start;
                align-items: center;
            }
        }
        .catalog-item__tags li, .workers {
            font-size: 1.3rem;
            line-height: 1;
        }
        .workers {
            margin: 0;
        }
    }
}
@media only screen and (max-width: 767px)
{
    .catalog-item {
        &.item-salon {
            max-width: 400px;
        }
    }
}
