.filters-container {
    font-family: 'Montserrat', sans-serif;
    background: transparent;
    color: #272727;
    border-radius: 4px;
    padding: 0 12px 11px 0;

    .active-filters {
        display: flex;
        position: relative;
        //overflow-x: auto;
        //flex-wrap: nowrap;
        flex: 1;
    }
    @include media-breakpoint-down(md) {
        .active-filters {
            overflow-x: auto;
            flex-wrap: nowrap;
        }
    }

    .filters-group {
        display: block;
        .filters-header, .filters-body {
            display: none;
        }
        .filters-header.opened {
            display: flex;
            padding-top: 6px;
        }
        .filters-body.opened {
            display: block;
            //padding-top: 6px;
        }
        //display: none;
        //&.opened {
        //    display: block;
        //    padding-top: 6px;
        //}
    }
}
.filters-header {
    span {
        font-size: 1.2rem;
        line-height: 34px;
        font-weight: 600;
        padding-left: 10px;
    }
    .filters {
        display: flex;
        overflow-x: auto;
        flex-wrap: nowrap;
        flex: 1;

        i {
            width: 11px;
            height: 11px;
            margin-left: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            border: 6px solid rgba(0, 0, 0, 0.18);
            border-left: 0;
            border-right: 0;
        }
        &::-webkit-scrollbar {
            height: 3px;
        }

        span {
            padding: 5px 10px;
            border-radius: 4px;
            background-color: #AA5060;
            display: flex;
            align-items: center;
            margin: 5px 0px 5px 10px;
            height: 24px;
            white-space: nowrap;

            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;

            &[data-filter-name="all-filters"] {
                background-color: #7a7a7a;

                .counter {
                    background-color: #7a7a7a;
                }
            }
        }

        .counter {
            padding: 0;
            margin: 0;
            margin-left: 5px;
        }

        &.mobile {
            display: none;
            .counter {
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }

    .filters.desktop {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(md) {
        .filters.desktop {
            display: none;
        }
        .filters.mobile {
            display: flex;
            position: relative;
        }
    }

}
.filters-body {
    .grid {
        gap: 5px;
        padding-top: 5px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(197px, 2fr));
    }
}
.filters-footer {
    padding-top: 15px;
}
.filter-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    position: relative;

    .hide {
        display: none;
    }
    &:hover {
        border: 1px solid #7e7e7e;

        .filter-button {
            color: #1c1c1c;

        }

        i {
            background-color: #636363;
        }
    }

    &.is-active,
    &.selected {
        border-color: #AA5060;

        .filter-button {
            color: #1c1c1c;

            i {
                -webkit-transform: scale(.75) translateY(-50%) rotate(-90deg);
                -ms-transform: scale(.75) translateY(-50%) rotate(-90deg);
                transform: scale(.75) translateY(-50%) rotate(-90deg);
                background-color: #AA5060;
            }
        }
    }
}
.filter-cell .filter-button {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8C8C8C;
    position: relative;
    padding: 5px 22px 5px 15px;
    min-width: 193px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    i {
        position: absolute;
        top: 50%;
        right: 13px;
        width: 9px;
        height: 13px;
        background-color: #8C8C8C;
        -webkit-transform: scale(.75) translateY(-50%) rotate(90deg);
        -ms-transform: scale(.75) translateY(-50%) rotate(90deg);
        transform: scale(.75) translateY(-50%) rotate(90deg);

        -webkit-transition: -webkit-transform .3s ease;
        transition: -webkit-transform .3s ease;
        -o-transition: transform .3s ease;
        transition: transform .3s ease;
        transition: transform .3s ease,-webkit-transform .3s ease;
    }
}
.filter-cell .filter-button span {
}

.catalog-filter__dropdown {
    position: absolute;
    z-index: 300;
    top: 100%;
    left: 0;
    display: none;
    overflow: hidden;
    width: 100%;
    margin-left: 0;
    margin-top: 2px;
    padding: 5px 0;
    border-radius: 10px;
    background: #fff;
    -webkit-box-shadow: 0 10px 50px rgb(0 0 0 / 20%);
    box-shadow: 0 10px 50px rgb(0 0 0 / 20%);

    .age {
        display: flex;
        justify-content: space-around;
        margin-bottom: 17px;
    }
}
.catalog-filter__dropdown.filter-services {
    width: auto;
    max-width: 600px;
}
.catalog-filter__dropdown.filter-services .catalog-filter__row{
    grid-template-columns: 200px 200px 200px;
    display: grid;
    gap: 19px 0;
}

.catalog-filter__dropdown.is-active {
    display: block;
    -webkit-animation: dropdown-display .4s ease;
    animation: dropdown-display .4s ease;
}

.catalog-filter__back {
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    line-height: 1.1;
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    padding: 13px 30px;
    cursor: pointer;
    text-align: left;
    color: #1e1e1e;
    border: 0;
    border-bottom: 1px solid #e6e6e6;
    outline: 0;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
}
.catalog-filter__back:after {
    content: "";
    position: absolute;
    top: calc(50% - 7px);
    left: 20px;
    margin-top: 1px;
    -webkit-transform: rotate(90deg) translateY(-50%);
    -ms-transform: rotate(90deg) translateY(-50%);
    transform: rotate(90deg) translateY(-50%);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    background: url(../svg/icon-dropdown.svg) no-repeat;
    width: 18px;
    height: 9px;
    display: inline-block;
}

.catalog-filter__scroll {
    max-height: 390px;
    padding: 9px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    scrollbar-width: thin;
}
