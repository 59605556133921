.modal.modal-review,
.modal.modal-abuse{
    .modal-content-wrap {

        .review-popup-row {
            display: flex;
            margin-bottom: 29px;
        }

        .review-popup-left {
            width: 310px;
            padding-right: 30px;
        }

        .review-popup-right {
            width: calc(100% - 310px);

            label {
                display: block;
                font-family: 'Montserrat', sans-serif;
                font-weight: 300;
                font-size: 1.4rem;
                margin-bottom: 10px;
            }

            & > div {
                margin-bottom: 15px;
            }
        }

        .review-switch {
            display: flex;
            align-items: center;

            .review-switch-button {
                flex-shrink: 0;
                display: block;
                width: 48px;
                height: 24px;
                padding: 2px;
                background-color: #D3D3D3;
                border-radius: 40px;
                margin-right: 16px;
                cursor: pointer;
                transition: all 0.3s;
                -webkit-tap-highlight-color: transparent;

                &.active {
                    background-color: #C0AC83;
                }
            }
            .review-switch-button.active .review-switch-button-inner {
                background-color: #F7F7FC;
                transform: translateX(24px);
            }

            .review-switch-button-inner {
                width: 20px;
                height: 20px;
                background-color: #FCFCFC;
                border-radius: 50%;
                transition: all 0.3s;
            }

            .review-switch-text {
                font-family: 'Montserrat', sans-serif;
                font-weight: 300;
                font-size: 1.4rem;
            }
        }

        .review-popup-rating-elements {
            margin-top: 20px;

            .review-popup-rating-element {
                display: flex;
                align-items: center;
                margin-bottom: 21px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .review-rating {
            display: flex;
        }

        .review-rating-label {
            flex-shrink: 0;
            width: 68px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            font-size: 1.4rem;
        }

        .popup-review-rating {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        .review-rating-star {
            width: 21px;
            height: 23px;
            margin-right: 12px;
            position: relative;

            input {
                border: none;
            }

            &:before {
                background: url("../svg/rating-star-transparent.svg") no-repeat center / contain;
                opacity: 1;
            }
            &:after {
                background: url("../svg/rating-star-black.svg") no-repeat center / contain;
                opacity: 0;
            }
        }

        .popup-review-rating .review-rating-star {
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            margin-bottom: 0;
            transition: opacity 0.3s;

            &.active {
                &:after {
                    opacity: 1;
                }
            }
        }

        .review-popup-checkboxes {
            display: flex;
            margin-top: 30px;
        }
        .review-popup-checkbox {
            display: flex;
            align-items: center;
            margin-right: 30px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &:last-child {
                margin-right: 0;
            }

            .text {
                font-family: 'Montserrat', sans-serif;
                font-weight: 300;
                font-size: 1.4rem;
                margin-right: 10px;
            }

            .jq-checkbox-own {
                width: 24px;
                height: 24px;
                padding-left: 22px;
                margin-right: 9px;

                .jq-checkbox__div-own {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .review-popup-text {
            font-size: 1.6rem;
            line-height: 1.2;
            letter-spacing: 0.01em;
            color: #b6a27a;
            margin-top: 30px;
        }

        .review-popup-submit {
            input {
                max-width: 276px;
                height: 45px;
                font-weight: 500;
            }
        }


        @media (max-width: 575px)
        {
            padding: 50px 15px 30px;
            .review-popup-row {
                flex-direction: column;
            }
            .review-popup-right {
                order: -1;
                margin-bottom: 30px;
            }
            .review-popup-left, .review-popup-right {
                width: 100%;
                padding: 0;
            }
            .modal-title {
                padding-bottom: 11px;
                font-size: 1.8rem;
                line-height: 1.2;
                margin-top: -30px;
            }
            .review-text {
                font-size: 1.4rem;
            }
        }
    }
}

.reviews-block {
    .actions {
        margin-top: 10px;
        ul {
            display: flex;
            gap: 10px;
        }
    }
    .is-owner {
        padding: 10px;
        background: #3bff001a;
        border-radius: 6px;
    }
    @media (max-width: 767px)
    {
        .review-right {
            width: 100%;
        }
        .review-left {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    @media (max-width: 575px)
    {
        .review-text {
            font-size: 1.4rem;
        }
    }
}