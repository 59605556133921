.catalog-text-short {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-top: 14px;
    margin-bottom: 13px;
    h1 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 27px;
        line-height: 34px;
    }
    h2 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
    }
    h3 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
    }
    h4 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }
    h5 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }
    h6 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    p  {
        padding: 6px 0;
    }

    .more-block {
        position: relative;
        white-space: nowrap;
    }
    .more {
        transition: unset;
        visibility: hidden;
        position: absolute;
        right: -9px;
        background: white;

        &:before {
            content: "";
            pointer-events: none;
            position: absolute;
            display: block;
            left: -50px;
            width: 50px;
            height: 100%;
            top: 0;
            background: linear-gradient(to right, transparent 0%, white 100%);
        }
    }
}
.text-full, .catalog-text-short {
    ul, ol {
        padding-left: 23px;
        li {
            p {
                padding: 0;
            }
        }
    }
    ul li {
        list-style-type: disc;
    }
    ol li {
        list-style-type: decimal;
    }
}
.text-full {
    padding-bottom: 11px;
}
.section-catalog__text.catalog-cities {
    padding-bottom: 20px;
}
.catalog-title {

    justify-content: space-between;
    .page__title {
        flex-grow: unset;
    }
    .follow {
        justify-content: flex-end;
        align-items: flex-start;
        color: darkred;
        &.isFollow {
            color: green;
        }
    }
    h5 {
        font-size: 1.3rem;
        font-weight: 500;
    }
}
.debug-seo {
    margin-bottom: 20px;
    display: flex;
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
    p {
        font-weight: 300;
    }

    .debug-column {
        padding: 15px;
        background-color: #e6f0f9;
    }

    p.active {
        color: green;
        font-weight: 500;
    }

    .seo-links {
        p.active {
            color: #0014ff;
            font-weight: 500;
        }
    }
}
