.header {
    background: #3d3d3d;
    width: 100%;
    min-width: 320px;
    text-align: left;
    -ms-flex-order: 1;
    order: 1;
    /* position: fixed; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999
}

.header-logo {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    margin: 0 15px;
    margin-right: auto;

    a {
        background-color: #C6B490;
        padding: 8px 15px;
        border-radius: 4px;
    }
}

.header-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0
}

.header-nav {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: block;
    margin: 0 10px 0 15px;
}

.header-nav__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;

    .add-advert {
        display: none;
    }
}

.header-nav__list li {
    overflow: hidden;
    transition: all .3s linear;
}
.header-nav__list li:hover {
    background-color: #fff;
}

.header-nav__list a {
    display: block;
    position: relative;
    font-family: Montserrat, sans-serif;
    color: #E7E7E7;
    font-size: 1.7rem;
    line-height: 1;
    padding: 20px 16px
}

.header-nav__list a:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    transform: translateX(-100%);
    background-color: #900C3F;
    transition: all .3s linear
}

.header-nav__list a:hover {
    color: #900C3F
}

.header-nav__list a:hover:after {
    transform: translateX(0)
}

.header-nav__list li.active a {
    color: #900C3F
}

.header-nav__list li.active a:after {
    transform: translateX(0)
}

.header-location {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: flex;
    margin: 0 15px;
    position: relative;
}

a.city {
    font-size: 1.7rem;
    position: relative;
    font-family: Montserrat, sans-serif;
    color: #E7E7E7;
    padding: 10px 10px;
    transition: none;
}
a.city:hover {
    color: #AA5060;
}

.header-functionals {
    margin: 10px 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-left: auto;
}

.header-functionals a {
    display: block;
    font-family: Montserrat, sans-serif;
    font-size: 1.2rem;
    line-height: 1;
    margin-left: 30px
}

.header-functionals a:first-child {
    margin-left: 0;
}

.header-functionals a:hover {
    text-decoration: underline
}

.header-functionals .profile-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.header-functionals .profile-button:after {
    content: "";
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 10px;
    display: block;
    width: 7px;
    height: 11px;
    background: url(../svg/icon-arrow-next-small.svg) no-repeat center / contain;
    transform: rotate(90deg);
}

.header-functionals .profile-button__dropdown {
    position: absolute;
    right: 0;
    top: calc(100% + 7px);
    padding-top: 15px;
    display: none
}

.header-functionals .profile-button__dropdown:before {
    content: "";
    position: absolute;
    border-bottom: 10px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    top: 5px;
    right: 20px
}

.header-functionals .profile-button__dropdown ul {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 8.6px 1.4px rgba(0, 0, 0, .3)
}

.header-functionals .profile-button__dropdown a {
    white-space: nowrap;
    font-size: 1.6rem;
    line-height: 2.8rem;
    font-family: PF DinDisplay Pro, sans-serif;
    color: #272727;
    font-weight: 400;
    text-transform: none;
    letter-spacing: .04em
}

.header-functionals .profile-button__dropdown a:after {
    display: none
}

.header-functionals .search {
    /*display: block;*/
    /*width: 24px;*/
    /*height: 20px;*/
    /*background-image: url(../svg/icon-loupe.svg);*/
    /*background-repeat: no-repeat;*/
    /*background-position: 50%;*/
    /*background-size: contain;*/
    /*transition: none;*/
    /*padding: 12px;*/
}

.header-functionals .search.active, .nav-mobile__head .search.active {
    background-image: url(../svg/icon-loupe-active.svg)
}

.header-functionals .search:hover {
    background-image: url(../svg/icon-loupe-active.svg)
}

.header-functionals__auth {
    display: none;
    background-color: #272727;
    padding: 30px;
    box-shadow: 0 1px 24.65px 4.35px rgba(0, 0, 0, .13);
    position: absolute;
    top: calc(100% + 2px);;
    right: 0
}

.header-functionals__auth-form {
    width: 282px
}


.header-functionals__auth-form .form-control {
    background-color: transparent !important;
    color: #ffffff;
}

.header-functionals__auth-form .box-field--inlined .box-field__label {
    -ms-flex: 0 0 71px;
    flex: 0 0 71px;
    color: #fff;
}

.header-functionals__auth-form .main-button {
    width: 212px
}

.header-functionals .auth-forgotten {
    display: block;
    margin: 0 0 0 auto;
    width: 212px;
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1;
    text-decoration: underline;
    font-weight: 400;
    font-family: PF DinDisplay Pro, sans-serif;
    margin-top: 10px;
    color: #fff;
}

.header-functionals .auth-forgotten:hover {
    text-decoration: none
}

.radius {
    cursor: pointer;
    display: flex;
    background: #ededed;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    position: relative;

    &.opened {
        .icon-arrow {
            transform: rotate(-90deg);
        }
    }

    &.disabled {
        background: #c5c5c5;
        border: 1px solid #c3b2b2;
        cursor: default;

        i.icon-arrow {
            background-color: rgb(26 25 38 / 32%);
        }
    }

    .d-flex {
        padding: 4px 6px 4px 11px;
    }

    .small-text {
        font-size: 13px;
        color: #797c9f;
        font-weight: 700;
    }
    .normal-text {
        font-weight: 600;
    }
    .group-center {
        display: flex;
        align-items: center;
        margin-left: 7px;
    }
    .icon-dropdown {
        padding: 5px;
    }
    i.icon-arrow {
        transform: rotate(90deg);
        width: 12px;
        height: 15px;
        background-color: #5653fb;
        transition: all 0.3s ease;
    }
}
.radius-dropdown {
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: 300;
    top: 100%;
    left: 0;
    width: 100%;
    margin-left: 0;
    margin-top: 2px;
    padding: 5px 0;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 10px 50px rgb(0 0 0 / 20%);

    &.active {
        display: block;
        overflow: visible;
    }

    .select {
        &:hover {
            background: #ddb9bf;
        }
        &.active {
            background: #ddb9bf;
        }
    }
}
