
.main {
    min-width: 320px;
    text-align: left;
    width: 100%;
    -ms-flex-order: 2;
    order: 2;
    -webkit-order: 2;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    padding-top: 57px;

    &.is-front {
        padding-top: 30px
    }

    @include media-breakpoint-down(md) {
        .section-catalog-main {
            padding-top: 3px!important;
        }
    }

    &.category {
        padding-top: 0;
    }
    &.region {
        padding-top: 21px;
    }
    &.home {
        padding-top: 21px;
    }
    &.search {
        padding-top: 21px;
    }

    > .wrapper >.ajax-pagination {
        padding-bottom: 30px;
    }
}


.page {

    &.is-filters {
        padding-top: 57px;

        @include media-breakpoint-up(md) {
            section.main-section {
                padding-bottom: 100px;
                min-height: 620px;
            }
        }
    }

    &.page-static {
        padding-top: 60px;

        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-size: 1.6rem;
        line-height: 2rem;
        padding-bottom: 13px;
        h1 {
            margin: 12px 0;
            font-weight: 500;
            font-size: 27px;
            line-height: 34px;
        }
        h2 {
            margin: 12px 0;
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
        }
        h3 {
            margin: 12px 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
        }
        h4 {
            margin: 12px 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        h5 {
            margin: 12px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }
        h6 {
            margin: 12px 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        p  {
            padding: 6px 0;
        }

        ul, ol {
            padding-left: 23px;
            li {
                p {
                    padding: 0;
                }
            }
        }
        ul li {
            list-style-type: disc;
        }
        ol li {
            list-style-type: decimal;
        }
    }

    &.page-map {
        padding-top: 60px;
        .content-header {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-size: 1.6rem;
            line-height: 2rem;
            h1 {
                margin: 12px 0;
                font-weight: 500;
                font-size: 27px;
                line-height: 34px;
            }
            h2 {
                margin: 12px 0;
                font-weight: 500;
                font-size: 22px;
                line-height: 27px;
            }
            h3 {
                margin: 12px 0;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
            }
            h4 {
                margin: 12px 0;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
            }
            h5 {
                margin: 12px 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
            h6 {
                margin: 12px 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }

            p  {
                padding: 6px 0;
            }

            ul, ol {
                padding-left: 23px;
                li {
                    p {
                        padding: 0;
                    }
                }
            }
            ul li {
                list-style-type: disc;
            }
            ol li {
                list-style-type: decimal;
            }
        }
    }
}

.page__title {
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    justify-content: flex-start;
    padding-bottom: 6px;
    position: relative;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 4rem;
    align-items: center;
}

.page-catalog__head {
    margin-bottom: 0px;

    @include media-breakpoint-down(md) {
        & {
            margin-bottom: 6px;
            padding-top: 0;
            margin-top: -1px;
        }
    }
}
.page-catalog__head .statistics-text {
    font-weight: 400;
}
.page-catalog__head .statistics-text span {
    font-weight: 600;
}

.page-catalog__head .m-sorting {
    position: static;
}
