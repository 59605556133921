
/* PRODUCT */
.product {
    padding-top: 0px;
}

.product-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.product-col {
    width: calc(65% - 60px);
    margin: 0 15px;
    &.gallery {
        width: 35%;
        .product-gallery {
            position: sticky;
            top: 0;
        }


        @media (max-width: 992px) and (min-width: 767px) {
            width: calc(100% - 30px);

            .product-gallery {
                flex-wrap: nowrap;
            }
            .product-thumbs-block {
                flex-shrink: 0;
                margin-right: 10px;
                order: -1;
                width: 126px;

                .product-thumb {
                    display: block;
                    height: 142px;
                    margin-bottom: 10px;
                    overflow: hidden;
                    width: 100%;
                }
            }
        }

        @media (max-width: 992px) {
            .product-thumb {
                //height: 72px;
                //width: 72px;
                &:nth-child(1n + 5) {
                    display: none;
                }
            }
        }

        @media (max-width: 767px) {
            width: calc(100% - 30px);
        }
    }
}

.product-gallery {
    display: flex;
    padding: 10px;
    background-color: #F6F6F6;
    flex-wrap: wrap;
}

.product-thumbs-block {
    order: 0;
    width: 100%;
    margin-top: 13px;
}

.product-thumbs-counter {
    font-weight: 200;
    font-size: 2rem;
    font-style: italic;
    color: #000000;
    margin-top: -3px;
    margin-bottom: 12px;
}

.product-thumbs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}

.product-thumb {
    height: 76px;
    width: calc(20% - 8px);
    margin: 0 4px 8px;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
}

.product-thumb:nth-child(5) {
    margin-bottom: 0;
}

.product-thumb:nth-child(1n + 6) {
    display: none;
}

.product-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-photo-block {
    width: 100%;
    position: relative;
}

.product-photo-block .catalog-item__statuses {
    right: 10px;
}

.product-photo {
    display: block;
    width: 100%;
    height: 100%;
}

.product-photo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.product-header.on-mobile {
    display: none;
}

.product-navigation .catalog-card__navigation {
    margin-left: 0;
    margin-right: 0;
}

.product-name {
    margin-right: 15px;
    margin-bottom: 0;
}

.product-name.on-mobile {
    display: none;
}

.product-activity.on-mobile {
    display: none;
}

.product-activity span {
    color: #272727;
}

.product-agency-row {
    display: flex;
    margin: 0 -20px 15px -20px;
}
.product-agency-row .product-agency,
.product-agency-row .product-site {
    margin: 0 20px;
    display: flex;
}
.product-agency-row .product-agency a,
.product-agency-row .product-site a {
    color: #292727;
}
.product-agency-row .product-agency a:hover,
.product-agency-row .product-site a:hover {
    color: #900C3F;
}
@media only screen and (min-width: 1280px) {
    .product-agency-row .product-site a {
        /*color: #0C2090;*/
    }
}
@media only screen and (max-width: 767px) {
    .product-agency-row {
        flex-direction: column;
    }
    .product-agency-row .product-agency {
        margin-bottom: 15px;
    }
}


.product-block-wrapper {
    border: 1px;
    border-style: solid;
    border-color: #900c3f;
    padding: 10px;
    background-color: #F6F6F6;
    margin-bottom: 22px;
    position: relative;

    &.info {
        border-color: #5c0829;
        background-color: #F6F6F6;
    }
}

.product-header-functions {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
}
.product-header-functions.visible-xs {

}

.product-favorite {
    margin-right: 10px;
}

.product-complain {
    padding-top: 1px;
}

.product-complain-button {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../svg/icon-close-main.svg') no-repeat center / contain;
}

.product-about {
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 22px;
}

.product-about-more {
    display: none;
}

.product-about-button {
    cursor: pointer;
}

.product-about-button span {
    color: #900C3F;
}

.product-phone-block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.product-phone {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #272727;
    margin-right: 15px;

    display: flex;
    align-items: center;
}

.product-phone::before {
    left: 0;
}

.product-conversation-start {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-style: italic;
    margin-bottom: 23px;
}

.product-features .catalog-item__tags {
    margin: 0;
}

.product-features .catalog-item__tags li {
    margin: 0;
    margin-right: 17px;
    font-size: 14px;
}
.product-features .catalog-item__tags li .icon {
    height: 18px;
}
.product-features .catalog-item__tags li.on-mobile {
    display: none;
}

.product-features .catalog-item__tags li:last-child {
    margin-right: 0;
}

.product-features .catalog-item__tags li:before {
    display: none;
}

.product-features .catalog-item__tags li a {
    color: #000000;
}

.product-features {
    &.found {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    &.in-pause {
        padding: 7px;
        .icon-warning {
            float: left;
            height: 23px;
            padding-right: 8px;
        }
        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
        }
    }

    &.location {
        padding-bottom: 13px;
        ul {
            width: 100%;
            padding: 10px;
            border-width: 1px 1px;
            border-color: #848484;
            border-style: solid;
        }
    }
}

.product-specs {
    margin-bottom: 19px;
}

.product-specs-block {
    margin-bottom: 10px;
}

.product-specs-block:last-child {
    margin-bottom: 0;
}

.product-specs-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.product-specs-col {
    width: calc(50% - 30px);
    margin: 0 15px;
}

.product-specs-item {
    display: flex;
    align-items: baseline;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #272727;
    margin-bottom: 5px;
}

.languages {
    display: flex;
}
.product-specs-item.language {
    margin-top: 5px;
    width: calc(50% - 15px);
}
.product-specs-item.language .value:last-child:before {

}
.first-language {
    order: 2;
}
.second-language {
    order: 1;
}
.product-specs-item.language.other-language {
}
.product-specs-item.language.other-language .value:before {
    content: ",\00a0";
}
.product-specs-item.language.other-language .value:last-child:before {
    content: "\00a0 и\00a0";
}
.second-language:after {
    content: ",\00a0";
}
.second-language.once:after {
    content: "";
}
@media (max-width: 575px) {
    .product-specs-item.language {
        width: 50%;
    }
    .product-specs-item.language .value {
        width: auto;
    }
    .product-specs-item.language.other-language {
        flex-wrap: wrap;
    }
    .product-specs-item.language.other-language .value:before {
        content: none;
    }
    .languages.show-xs .other-language .value:first-child:before {
        content: ",\00a0";
    }
    .languages.show-xs .other-language .value:nth-last-child(n+3):after {
        content: ",\00a0";
    }
    .languages.show-xs .other-language .value:last-child:before {
        content: "\00a0 и\00a0";
    }

    /*.product-specs-item {*/
    /*    font-size: 1.4rem;*/
    /*}*/
}

.product-specs-item .separator {
    flex-grow: 1;
    border-bottom: 1px dotted #AAAAAA;
    margin: 0 5px;

}
.product-specs-item:nth-child(2n) {
    .separator {
        border-bottom: 1px dotted #000;
    }
}

.product-specs-item .value {
    font-weight: 500;
}

.product-specs-item a {
    color: #272727;
}

.prices-times {
    display: flex;
    margin: 0 -10px;
}

.prices-times .page__title,
.product-specs .page__title,
.product-features .page__title{
    font-size: 20px;
}
.prices-times .product-prices {
    margin: 0 10px;
    &.schedule {
        tr.current-day {
            background: whitesmoke;
        }
    }
}
.prices-times .schedule tr:first-child {
    border-top: 1px solid #e4e4e4;
}


.product-prices-table.on-mobile {
    display: none;
}

.product-prices-table th, .product-prices-table td {
    padding: 9px 16px;
    border: none;
}

.product-prices-table th {
    height: 50px;
    background-color: #e4e4e4;
    font-weight: 500;
    font-size: 1.6rem;
    color: #000000;
}

.product-prices-table th:first-child {
    border-left: 1px solid #e4e4e4;
}

.product-prices-table th:last-child {
    border-right: 1px solid #e4e4e4;
}

.product-prices-table td {
    height: 38px;
    background-color: transparent;
    font-weight: bold;
    font-size: 1.6rem;
    color: #900C3F;
}

.product-prices-table td:first-child {
    font-weight: normal;
    color: #272727;
    border-left: 1px solid #e4e4e4;
}

.product-prices-table td:last-child {
    border-right: 1px solid #e4e4e4;
}

.product-prices-table tbody tr {
    border-bottom: 1px solid #e4e4e4;
}

.product-prices-table .icon-title {
    display: flex;
    align-items: center;
}

.product-prices-table .icon-title::before {
    content: '';
    display: block;
    /*width: 21px;*/
    height: 21px;
    /*margin-right: 10px;*/
}

.product-prices-table .icon-acomodation::before {
    /*background: url('../svg/icon-apartments-custom.svg') no-repeat center / contain;*/
}

.product-prices-table .icon-place::before {
    background: url('../svg/icon-home.svg') no-repeat center / contain;
}
.product-prices-table .icon-car::before {
    /*background: url('../svg/icon-car-custom.svg') no-repeat center / contain;*/
}


@media (max-width: 767px) {
    .prices-times {
        flex-direction: column;
    }
    .product-prices:nth-child(1) {
        margin-bottom: 19px;
    }
}


.product-services-block {
    margin-top: 7px;
}


.abuse-block {
    margin-top: 35px;

    .abuse-title {
        font-weight: 400;
        font-size: 19px;
        text-decoration: underline dotted;
        text-underline-offset: 7px;
    }
}

.product-services {
    padding: 30px 45px;
    padding-left: 130px;
    background-color: #F6F6F6;
}

.product-services-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.product-services-col {
    width: 220px;
    margin: 0 15px;
}

.product-services ul {
    margin-bottom: 26px;

    li .description {
        padding-left: 13px;
        padding-bottom: 7px;
        font-size: 13px;

        p {
            color: #007f00;
        }
    }
}

.product-services ul:last-child {
    margin-bottom: 0;
}

.product-services h3 {
    font-size: 1.6rem;
    line-height: 100%;
    color: #000000;
    font-weight: 600;
    margin: 0 0 12px;
}

.product-services a, .product-services span.alink {
    display: inline-block;
    padding-left: 13px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #000000;
    position: relative;
}
.product-services span.alink {
    font-size: 1.4rem;
    line-height: 1.8rem;
}

.dopuslugi span.alink {position: relative;}



.product-services a::before, .dopuslugi span.alink::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: url('../svg/services-check-icon.svg') no-repeat;
    position: absolute;
    left: 0;
    top: 8px;
}

.product-services a:hover {
    color: #0C2090;
}

.product-services .line_none a::before {
    display: none;
}

.line_none a {
    opacity: 0.2;
    text-decoration: line-through;
}

.product-services .optional {
    padding: 0 6px;
    background-color: #900C3F;
    font-size: 1.4rem;
    line-height: normal;
    color: #ffffff;
    margin-left: 9px;
}

.service-comment {
    padding-left: 13px;
    line-height: 1.8rem;
    padding-bottom:5px;
    color: #8cb74f;
}


.product-location-block {
    margin-top: 35px;
}

.product-location-block .route {
    display: flex;
    padding-bottom: 13px;
}
.product-location-block .route .page__title {
    flex-grow: inherit;
}
.product-location-block .route .route-button {
    margin-left: 20px;
}

.map-tag {
    background-color: #900C3F;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
    margin-bottom: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}
.map-tag::after {
    content: "";
    position: absolute;
    left: 50%;
    top: calc(100% - 1px);
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #900C3F;
}

@media (min-width: 1281px) {
    .product-features .catalog-item__tags {
        /*flex-wrap: wrap;*/
    }
    .product-features .catalog-item__tags li {
        margin-right: 23px;
        /*flex: 0 0 50%;*/
    }
    .product-features .catalog-item__tags li:nth-child(1),
    .product-features .catalog-item__tags li:nth-child(2){
        /*margin-bottom: 5px;*/
    }
    .product-agency-row {
        flex-wrap: wrap;
        margin: 0 0 15px 0;
    }
    .product-agency-row .product-agency,
    .product-agency-row .product-site {
        flex: 0 0 50%;
        margin: 0;
    }
}
@media (max-width: 1280px) {
    .product-col {
        width: calc(100% - 30px);
    }

    //.product-col:first-child {
    //    margin-bottom: 40px;
    //}

    .product-services {
        padding: 30px 45px;
    }

    .product-services-col {
        width: calc(25% - 30px);
    }
}

@media  (min-width: 992px) and (max-width: 1280px) {
    .product-col {
        width: calc(40% - 30px);
    }
    .product-col:last-child {
        width: calc(60% - 30px);
    }
    .product-gallery {
        flex-wrap: wrap;
        display: flex;
        padding: 10px;
        background-color: #F6F6F6;
    }
    //.product-col:first-child {
    //    margin-bottom: 40px;
    //}
    .product-photo-block {
        width: 100%;
    }
    .product-thumbs-block {
        order: 0;
        width: 100%;
        margin-right: 0;
    }
    .product-thumbs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;
    }
    .product-thumb {
        height: 73px;
        margin: 0 4px 8px;
    }
    .product-prices-table td {
        padding: 9px 12px;
    }
}
@media (max-width: 992px) {
    .product-services {
        padding-bottom: 10px;
    }
    .product-services-col {
        width: calc(50% - 30px);
        margin-bottom: 20px;
    }
    .product-services-block {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .product-gallery {
        flex-wrap: wrap;
        padding: 8px 8px 0;
    }

    .product-photo-block {
        width: 100%;
        height: 420px;
        margin-bottom: 8px;
    }

    .product-thumbs-block {
        order: 0;
        width: 100%;
        margin-right: 0;
    }

    .product-thumbs-counter {
        display: none;
    }

    .product-thumbs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;
    }

    .product-thumb {
        width: calc(20% - 8px);
        height: 92px;
        margin: 0 4px 8px;
    }

    .product-thumb:nth-child(4) {
        margin-bottom: 8px;
    }

    .product-thumb:nth-child(1n + 5) {
        display: block;
    }

}
@media (max-width: 599px) {
    .product-features .catalog-item__tags {
        flex-direction: column;
    }

    .product-features .catalog-item__tags li {
        margin-bottom: 8px ;
    }
    .product-features .catalog-item__tags li:last-child {
        margin-bottom: 0;
    }
}
@media (max-width: 575px) {
    .product {
        padding-top: 22px;
    }

    .product-header.on-desctop {
        display: none;
    }

    .product-header.on-mobile {
        display: flex;
    }

    .product-header .catalog-card__arrow {
        width: 32px;
        height: 32px;
        background-size: 10px 17px;
    }

    .product-header .catalog-card__arrow--next {
        margin-left: 10px;
    }

    .product-name {
        margin-top: -2px;
    }
    .product-header .page__title:after, .product-header-functions .page__title:after {
        left: -34px;
    }

    .product-name.on-mobile {
        display: block;
        margin-right: 70px;
        margin-top: -3px;
        margin-bottom: 22px;
    }

    .product-activity.on-desctop {
        display: none;
    }

    .product-activity.on-mobile {
        display: flex;
        align-items: center;
    }

    .product-activity {
        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    .product-col:first-child {
        margin-bottom: 13px;
    }

    .product-gallery {
        padding: 4px 4px 0;
        margin: 0 -8px;
    }

    .product-photo-block {
        margin-bottom: 4px;
    }

    .product-thumbs {
        margin: 0 -2px;
    }

    .product-thumb {
        width: calc(100% / 3 - 4px);
        margin: 0 2px 4px;
        height: 107px;
    }

    .product-thumb:nth-child(4) {
        margin-bottom: 4px;
    }

    .product-photo-block .catalog-item__statuses {
        top: 10px;
    }

    .product-photo-block .catalog-item__vip, .product-photo-block .catalog-item__checked, .product-photo-block .catalog-item__video, .product-photo-block .catalog-item__indi {
        width: 55px;
        height: 55px;
        margin-bottom: -6px;
    }

    .product-about {
        font-size: 1.4rem;
        line-height: 2.1rem;
        margin-bottom: 25px;
    }

    .product-block-wrapper {
        padding: 15px 15px 20px;
    }

    .desctop-heart .main-button--wish {
        margin-top: 5px;
        margin-left: -25px;
    }

    .product-phone-block {
        margin-bottom: 24px;
    }

    .product-features .catalog-item__tags {
        /*flex-direction: column;*/
    }

    .product-features .catalog-item__tags li {
        font-size: 1.4rem;
        margin-right: 0;
    }

    .product-features .catalog-item__tags li.on-mobile {
        display: flex;
    }

    .product-features .catalog-item__tags li:last-child {
        margin-bottom: 0;
    }

    .product-specs {
        margin-bottom: 18px;
    }

    .product-specs-block:last-child {
        margin-bottom: 0;
    }

    .product-specs-block:first-child .product-specs-col:nth-child(1) {
        order: 1;
    }

    .product-specs-block:first-child .product-specs-col:nth-child(2) {
        order: 3;
    }

    .product-specs-block:first-child .product-specs-col:nth-child(3) {
        order: 2;
    }

    .product-specs-block:first-child .product-specs-col:nth-child(4) {
        order: 4;
    }

    .product-specs-col {
        width: calc(100% - 30px);
    }

    .product-specs-item {
        font-size: 1.4rem;
    }

    .product-specs-item .value {
        width: 50%;
        font-weight: bold;
    }
    .product-prices-table.on-desctop {
        display: none;
    }

    .product-prices-table.on-mobile {
        display: block;
    }

    .product-prices-table table:first-child {
        margin-bottom: 30px;
    }

    .product-prices-table td:first-child {
        font-size: 1.4rem;
    }

    .product-prices-table td:last-child {
        /*text-align: right;*/
    }

    .product-services-block {
        margin-top: 30px;
    }

    .product-services {
        padding: 20px 12px 1px;
    }

    .product-services-row {
        margin: 0 -7px;
    }

    .product-services-col {
        width: calc(50% - 14px);
        margin: 0 7px 31px;
    }

    .product-services h3 {
        font-size: 1.2rem;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 6px;
    }

    .product-services ul {
        margin-bottom: 34px;
    }

    .product-services li {
        margin-bottom: 3px;
    }

    .product-services li:last-child {
        margin-bottom: 0;
    }

    .product-services a {
        font-size: 1.4rem;
        line-height: 144%;
    }

    .product-services .optional {
        padding-top: 3px;
    }

    .product-services a::before {
        top: 6px;
    }

}

@media (max-width: 400px) {
    .product-features .catalog-item__tags {
        flex-direction: column;
    }
    .product-features .catalog-item__tags li {
        /*margin-bottom: 8px !important;*/
    }
    .product-features .catalog-item__tags li:last-child {
        /*margin-bottom: 0 !important;*/
    }
}
