.catalog-links {
    .links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 20px;
    }

    a {
        text-align: center;
        transition: all 0.3s linear;
        background-color: #f0f0f0;
        color: #272727;
        font-size: 1.6rem;
        padding: 10px;

        &:hover {
            color: #900C3F;
        }
    }
}
