.main.user-panel {
    a {
        color: #900C3F;
        &:hover {
            color: #AAAAAA;
        }
    }
    .sidebar {
        width: 200px;

        .sidebar-menu {
            background-color: #F2F2F2;
            padding: 20px 20px 10px 20px;
            border-radius: 4px;
            border: 1px solid #3d3d3d;
            margin-right: 20px;
            margin-top: 10px;
        }

        li {
            font-size: 20px;
            padding: 5px 0;
        }
    }

    .content {
        flex-grow: 1;
    }
    .wrapper > .flex-container {
        flex-wrap: nowrap;
    }
    .content {
        .block {
            padding: 20px;
            background-color: #f4f4f4;
            margin: 10px;



            .text-success {
                color: green;
            }
        }
        .block-header {
            font-size: 20px;
            margin: 0 0 10px 10px;
            padding: 0;
        }
        .form {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;

            .form-wrapper {
                background-color: #F2F2F2;
                padding: 20px 20px 10px 20px;
                border-radius: 4px;
                border: 1px solid #3d3d3d;
                max-width: 520px;
            }
        }
        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: none;
            margin: 0 0 10px;

            label {
                width: calc(100% / 3 - 10px);
                min-width: 91px;
                span {
                    color: #900C3F;
                }
            }

            &.justify-center {
                justify-content: center;
            }
        }

        .form-group {
            width: 100%;
            display: flex;
            flex-direction: column;

            .form-control {
                width: initial;
                margin: 0;
            }

            .text-danger {
                font-size: 15px;
                color: #900C3F;
                padding: 5px 0 3px 10px;
            }
        }

        input[type=submit] {
            height: 40px;
            width: calc(53.3333333333% - 10px);
            border: none;
            background-color: #900C3F;
            font-weight: 600;
            color: #fff;
            background-image: none;
        }

        .field {
            width: 100%;
            display: flex;
        }
    }
}
.header-form .form.user-panel {
    &.reviews {
        margin-top: 0;
    }
}