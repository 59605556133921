.form {
    font-family: Montserrat, sans-serif;
    padding-bottom: 4px;

    &.region {
        padding-bottom: 21px;
    }
    &.home {
        padding-bottom: 21px;
    }

    .form-limit-width {
        max-width: 690px;
    }
    //&:after {
    //    content: '';
    //    background: url("~/assets/img/image.png");
    //}

    & .select:hover,& .button-city:hover, & .button-location:hover, & .button-search:hover {
        cursor: pointer;
        box-shadow: 0 1px 24.65px rgba(0, 0, 0, 0.13);
        &:has(.radius:hover) {
            box-shadow: none;
        }
    }

    .select-group {
        display: flex;
        margin-bottom: 16px;

        .select {
            margin-bottom: 0;
            border-radius: 4px 0 0 4px;
        }
    }
    .catalog-title {
        margin-bottom: 7px;
    }

    h1 {
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 4rem;
        color: #3D3D3D;
    }

    .catalog-text-short {
        font-style: normal;
        color: #646464;

        h1 {
            font-weight: 500;
            font-size: 27px;
            line-height: 34px;
            margin-bottom: 12px;
        }
    }

    .select {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 15px;
        gap: 10px;

        .icon-left {
            width: 26px;
            margin-top: 3px;
        }

        .content {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 17px;
            color: #494949;
            min-width: 0;
            width: 100%;
        }
        .text {
            text-align: left;
        }
    }

    .map {
        margin-top: 24px;
        position: relative;
        height: 219px;
        background: url(/img/city-map.png) no-repeat;
        background-size: cover;

        img {
            position: absolute;
            z-index: -1;
        }
    }

    .debug-seo {
        margin-top: 24px;
        margin-bottom: 0;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 16px;
        flex-wrap: wrap;

        .button-city {
            background: #FEFEFE;
            border-radius: 4px;
            color: #C2818D;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            flex-grow: 10;
            text-align: center;
            a {
                padding: 10px 20px;
                display: block;
            }
        }
    }
    .button-location, .button-search {
        display: flex;
        background: #C2818D;
        backdrop-filter: blur(2px);
        border-radius: 0 4px 4px 0;
        padding: 8px 15px;

        left: calc(50% - 104px);
        top: calc(50% - 20px);

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #fff;

        .text {
            padding-left: 12px;
            white-space: nowrap;
        }

        &.loading {
            background: #b9b9b9;
            color: #b9b9b9;
            .loading-spinner {
                display: block;
            }
        }

        &.checked {
            svg {
                path {
                    stroke: #5653fb;
                }
            }
        }
    }

    .select-city, .select-category {
        position: relative;
    }

    .select-category.disabled .select {
        background: #e3e3e3;
        border-color: #e3e3e3;
        &:hover {
            cursor: default;
            box-shadow: none;
        }
    }
    .category-form {
        .geolocation-subcity span {
            padding: 7px 30px 7px 30px;
        }
    }

    .select-city {
        width: 310px;
        min-width: 0;

        .text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .loading-spinner {
        display: none;
        width: 35px;
        height: 35px;
        top: 3px;
        left: 50%;
    }
}
