.wrapper {
    min-width: 320px;
    max-width: 1278px;
    padding: 0 30px;
    margin: 0 auto;
    position: relative
}

.hidden {
    display: none!important
}
.p-0 {
    padding: 0!important;
}
.d-flex {
    display: flex;
}
.justify-center {
    justify-content: center;
}
.w-full {
    width: 100%;
}

.filter-container,
.flex-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 -15px;
}
.flex-container.ajax-nearest-city{
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 11px;
    align-items: center;
}

.related_news {
    margin-top: 25px;
}

.section-catalog .flex-container,
.page-catalog .flex-container {

    min-height: 400px;
}

.flex-container--full-sized {
    margin: 0
}

.flex-container--full-sized .flex-lg {
    padding: 0
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
}
.flex-inline {
    display:inline-flex;
}

.flex-xl-12 {
    padding: 0 15px;
    width: 100%
}

.flex-xl-11 {
    padding: 0 15px;
    width: 91.66667%
}

.flex-xl-10 {
    padding: 0 15px;
    width: 83.33333%
}

.flex-xl-9 {
    padding: 0 15px;
    width: 75%
}

.flex-xl-8 {
    padding: 0 15px;
    width: 66.66667%
}

.flex-xl-7 {
    padding: 0 15px;
    width: 58.33333%
}

.flex-xl-6 {
    padding: 0 15px;
    width: 50%
}

.flex-xl-5 {
    padding: 0 15px;
    width: 41.66667%
}

.flex-xl-4 {
    padding: 0 15px;
    width: 33.33333%
}

.filter-item,
.flex-xl-3 {
    padding: 0 15px;
    width: 25%
}

.flex-xl-2 {
    padding: 0 15px;
    width: 16.66667%
}

.flex-xl-1 {
    padding: 0 15px;
    width: 8.33333%
}

.flex-vertical-center {
    -ms-flex-align: center;
    align-items: center
}

.flex-vertical-start {
    -ms-flex-align: start;
    align-items: flex-start
}

.flex-vertical-end {
    -ms-flex-align: end;
    align-items: flex-end
}

.flex-horizontal-center {
    -ms-flex-pack: center;
    justify-content: center
}

.flex-horizontal-start {
    -ms-flex-pack: start;
    justify-content: flex-start
}

.flex-horizontal-end {
    -ms-flex-pack: end;
    justify-content: flex-end
}

.flex-horizontal-between {
    -ms-flex-pack: justify;
    justify-content: space-between
}
.ajax-nearest-city {
    margin-top: 20px;
}
.ajax-nearest-city a.main-button {
    min-width: 280px;
}
.ajax-nearest-city a.main-button.return-parent {
    min-width: 354px;
}
.nearest-worksheets {
    display: flex;
    width: 100%;
}
@media only screen and (max-width:1280px) {
    main .wrapper {
        /*max-width: 960px*/
    }
}

@media only screen and (max-width:1180px) {
    .wrapper {
        max-width: 960px
    }
    .hidden-lg {
        display: none !important
    }
    .flex-lg-12 {
        padding: 0 15px;
        width: 100%
    }
    .flex-lg-11 {
        padding: 0 15px;
        width: 91.66667%
    }
    .flex-lg-10 {
        padding: 0 15px;
        width: 83.33333%
    }
    .flex-lg-9 {
        padding: 0 15px;
        width: 75%
    }
    .flex-lg-8 {
        padding: 0 15px;
        width: 66.66667%
    }
    .flex-lg-7 {
        padding: 0 15px;
        width: 58.33333%
    }
    .flex-lg-6 {
        padding: 0 15px;
        width: 50%
    }
    .flex-lg-5 {
        padding: 0 15px;
        width: 41.66667%
    }
    .flex-lg-4 {
        padding: 0 15px;
        width: 33.33333%
    }
    .flex-lg-3 {
        padding: 0 15px;
        width: 25%
    }
    .flex-lg-2 {
        padding: 0 15px;
        width: 16.66667%
    }
    .flex-lg-1 {
        padding: 0 15px;
        width: 8.33333%
    }
}

@media only screen and (max-width:991px) {
    .hidden-md {
        display: none!important
    }
    .wrapper,
    main .wrapper {
        max-width: 720px
    }
    .flex-md-12 {
        padding: 0 15px;
        width: 100%
    }
    .flex-md-11 {
        padding: 0 15px;
        width: 91.66667%
    }
    .flex-md-10 {
        padding: 0 15px;
        width: 83.33333%
    }
    .flex-md-9 {
        padding: 0 15px;
        width: 75%
    }
    .flex-md-8 {
        padding: 0 15px;
        width: 66.66667%
    }
    .flex-md-7 {
        padding: 0 15px;
        width: 58.33333%
    }
    .flex-md-6 {
        padding: 0 15px;
        width: 50%
    }
    .flex-md-5 {
        padding: 0 15px;
        width: 41.66667%
    }
    .flex-md-4 {
        padding: 0 15px;
        width: 33.33333%
    }
    .flex-md-3 {
        padding: 0 15px;
        width: 25%
    }
    .flex-md-2 {
        padding: 0 15px;
        width: 16.66667%
    }
    .flex-md-1 {
        padding: 0 15px;
        width: 8.33333%
    }
}

@media only screen and (max-width:767px) {
    .hidden-sm {
        display: none!important
    }
    .wrapper,
    main .wrapper{
        max-width: 540px;
        padding: 0 15px
    }
    .flex-sm-12 {
        padding: 0 15px;
        width: 100%
    }
    .flex-sm-11 {
        padding: 0 15px;
        width: 91.66667%
    }
    .flex-sm-10 {
        padding: 0 15px;
        width: 83.33333%
    }
    .flex-sm-9 {
        padding: 0 15px;
        width: 75%
    }
    .flex-sm-8 {
        padding: 0 15px;
        width: 66.66667%
    }
    .flex-sm-7 {
        padding: 0 15px;
        width: 58.33333%
    }
    .flex-sm-6 {
        padding: 0 15px;
        width: 50%
    }
    .flex-sm-5 {
        padding: 0 15px;
        width: 41.66667%
    }
    .flex-sm-4 {
        padding: 0 15px;
        width: 33.33333%
    }
    .flex-sm-3 {
        padding: 0 15px;
        width: 25%
    }
    .flex-sm-2 {
        padding: 0 15px;
        width: 16.66667%
    }
    .flex-sm-1 {
        padding: 0 15px;
        width: 8.33333%
    }
}
@media only screen and (min-width:576px) {
    .show-xs {
        display: none!important
    }
}
@media only screen and (max-width:575px) {
    .wrapper,
    main .wrapper{
        padding: 0 20px
    }
    .hidden-xs {
        display: none!important
    }
    .flex-xs-12 {
        padding: 0 15px;
        width: 100%
    }
    .flex-xs-11 {
        padding: 0 15px;
        width: 91.66667%
    }
    .flex-xs-10 {
        padding: 0 15px;
        width: 83.33333%
    }
    .flex-xs-9 {
        padding: 0 15px;
        width: 75%
    }
    .flex-xs-8 {
        padding: 0 15px;
        width: 66.66667%
    }
    .flex-xs-7 {
        padding: 0 15px;
        width: 58.33333%
    }
    .flex-xs-6 {
        padding: 0 15px;
        width: 50%
    }
    .flex-xs-5 {
        padding: 0 15px;
        width: 41.66667%
    }
    .flex-xs-4 {
        padding: 0 15px;
        width: 33.33333%
    }
    .flex-xs-3 {
        padding: 0 15px;
        width: 25%
    }
    .flex-xs-2 {
        padding: 0 15px;
        width: 16.66667%
    }
    .flex-xs-1 {
        padding: 0 15px;
        width: 8.33333%
    }

    .section-catalog .flex-container, .page-catalog .flex-container, .related_news .flex-container {
        margin: 0 -18px;
    }

    .section-catalog .flex-sm-6, .page-catalog .flex-sm-6, .related_news .flex-sm-6 {
        padding: 0 2px;
    }
}

@media only screen and (min-width:576px) {
    .visible-xs {
        display: none!important
    }
}

@media only screen and (min-width:768px) {
    .visible-sm {
        display: none!important
    }
}

@media only screen and (min-width:992px) {
    .visible-md {
        display: none!important
    }
}

@media only screen and (min-width:1200px) {
    .visible-lg {
        display: none!important
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: PF DinDisplay Pro, sans-serif;
    background-color: #fff;
    height: 100%;
    line-height: 1;
    position: relative;
    font-size: 1.6rem!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
    overflow: hidden;
}

.align-items-center {
    align-items:center;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-width: 0;
    padding: 0;
    margin: 0
}

html {
    height: 100%;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    font-size: 62.5%
}

input,
textarea {
    color: #272727;
    font-family: PF DinDisplay Pro, sans-serif;
    outline: none;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none
}

button,
input[type=button],
input[type=submit] {
    cursor: pointer
}

form,
td {
    margin: 0;
    padding: 0
}

a,
button {
    color: #900C3F;
    transition: all .3s linear;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

a,
button,
div,
span {
    outline: none!important
}

button,
input[type=button],
input[type=submit] {
    -webkit-appearance: none;
    outline: none;
    padding: 0
}

*,
:after,
:before {
    box-sizing: border-box
}

li,
ol,
ul {
    list-style-type: none
}

img {
    display: block;
    max-width: 100%;
    height: auto
}

.black {
    color: black !important;
}

h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 500
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Regular.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-LightItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-LightItalic.woff) format("woff");
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-ExtraBold.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-ExtraBold.woff) format("woff");
    font-weight: 800;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Italic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Black.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Black.woff) format("woff");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Light.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-BoldItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-BoldItalic.woff) format("woff");
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-ThinItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-ThinItalic.woff) format("woff");
    font-weight: 100;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Thin.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Thin.woff) format("woff");
    font-weight: 100;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff) format("woff");
    font-weight: 800;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-SemiBoldItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-SemiBoldItalic.woff) format("woff");
    font-weight: 600;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-MediumItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-MediumItalic.woff) format("woff");
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Bold.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-ExtraLightItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-ExtraLightItalic.woff) format("woff");
    font-weight: 200;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-SemiBold.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-SemiBold.woff) format("woff");
    font-weight: 600;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-BlackItalic.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-BlackItalic.woff) format("woff");
    font-weight: 900;
    font-style: italic
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-Medium.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: Montserrat;
    src: url(../fonts/Montserrat/Montserrat-ExtraLight.woff2) format("woff2"), url(../fonts/Montserrat/Montserrat-ExtraLight.woff) format("woff");
    font-weight: 200;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-MediumItalic.woff) format("woff");
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Thin.woff) format("woff");
    font-weight: 100;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-BlackItalic.woff) format("woff");
    font-weight: 900;
    font-style: italic
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-BoldItalic.woff) format("woff");
    font-weight: 700;
    font-style: italic
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Black.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Black.woff) format("woff");
    font-weight: 900;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Light.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-Light.woff) format("woff");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-LightItalic.woff) format("woff");
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: PF DinDisplay Pro;
    src: url(../fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.woff2) format("woff2"), url(../fonts/PFDinDisplayPro/PFDinDisplayPro-ThinItalic.woff) format("woff");
    font-weight: 100;
    font-style: italic
}

select {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8C8C8C;
    padding: 5px 15px 5px 15px;

    &.checked {
        color: #1c1c1c;
    }
    &:focus-visible {
        outline: none;
    }
}

.hide-image {

}
