.form.search {
  .button-search .text {
    padding-left: 0;
  }

  input {
    font-weight: 300;
    line-height: 17px;
    font-size: 17px;
    width: 100%;
    border: 0;
  }
  .select-city {
    width: 100%;
  }
    .search-error {
        color: darkred;
        padding: 0px 10px 10px;
    }
}
