i {
    width: 16px;
    height: 16px;
    display: block;

    &.icon-filter {
        background-image: url(../svg/icon-filter.svg);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    &.icon-arrow {
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url(../svg/icon-dropdown-filter.svg);
        mask-image: url(../svg/icon-dropdown-filter.svg);
        background-color: #8C8C8C;
        background-repeat: no-repeat;
        background-position: 50%;
    }

    &.icon-wifi {
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url(../svg/icon-wifi.svg);
        mask-image: url(../svg/icon-wifi.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        width: 22px;
        background-color: #3F3F46;
    }

    &.icon-circle {
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: url(../svg/icon-x-circle.svg);
        mask-image: url(../svg/icon-x-circle.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-color: #F2F2F2;
        cursor: pointer;
    }
}
