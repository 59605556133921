$break-desktop            : 1081px;
$break-table              : 721px;
#map {
    height: 100%;
    width: 100%;

    .cluster-images {
        display: flex;
        flex-wrap: wrap;
        img {
            max-height: 139px;
            margin: 5px;
            border-radius: 9px;
            cursor: pointer;
        }
    }

    .marker-icon {
        img {
            height: 50px;
            width: auto;
            transition: all .1s linear;
        }
        &.has-photo {
            border: 2px solid white;
            border-radius: 7px;
            transition: all .1s linear;
            img {
                height: 50px;
                width: auto;
                border-radius: 7px;
            }
    
            &:hover, &.opened {
                border: 5px solid white;
                img {
                    height: 80px;
                }
            }
            .marker-photo {
                display: block;
            }
            .marker-type-icon {
                display: none;
            }
        }

        &.no-photo {
            img {
                height: 48px;
                border-radius: 50%;
            }
            &:hover, &.opened {
                img {
                    height: 68px;
                }
            }
            .marker-photo {
                display: none;
            }
            .marker-type-icon {
                display: block;
            }
        }
    }

    .intimacy-map {
        position : relative;
        z-index  : 50;
    
        > .wrapper {
            position : relative;
        }
        .leaflet-popup-content {
            min-width: 300px;
        }
    }

    .map-filter {
        position      : absolute;
        top           : 20px !important;
        right         : 20px !important;
        left          : 20px !important;
        
        @media screen and (min-width : 851px) {
            top     : 40px;
            right   : auto;
            width   : 270px;
        }
    }
    
    .intimacy-map__filter {
        box-sizing    : border-box;
        border-radius : 10px;
        background    : #FFF;
        z-index       : 1;
        box-shadow    : 0 10px 50px rgba(0, 0, 0, 0.2);

        @media screen and (min-width : 851px) {
            padding : 23px 20px;
        }

        .catalog-filter {
            margin-bottom : 0;
            @media screen and (max-width : 850px) {
                padding : 10px;
                display: none;

                &.is-active {
                    display: block;
                }
            }
            
        }
    
        .intimacy-map__mobile {
            margin-bottom   : 0;
            width           : 100%;
            justify-content : center;
            padding: 6px;
    
            > i {
                flex : 0 0 auto;
            }
    
            span {
                margin-left  : auto;
                margin-right : auto;
                font-size    : 13px;
            }
    
            @media screen and (min-width : 851px) {
                display    : none;
                visibility : hidden;
            }
            @media screen and (min-width : $break-desktop) {
    
            }
        }
    
        .intimacy-map__footer {
            margin : 15px -10px -10px;
            @media screen and (min-width : 851px) {
                display    : none;
                visibility : hidden;
            }
        }
    
        .intimacy-map__close {
            outline                     : 0;
            -webkit-tap-highlight-color : transparent;
            border                      : 0;
            cursor                      : pointer;
            -webkit-box-sizing          : border-box;
            -moz-box-sizing             : border-box;
            box-sizing                  : border-box;
            background                  : transparent;
            text-align                  : center;
            width                       : 100%;
            padding                     : 10px;
            font-size                   : 14px;
            color                       : $blue;
            border-top                  : 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    
    
    .intimacy-map__map {
        height        : 700px;
        margin-top    : -25px;
        margin-bottom : -45px;
        border-radius : 15px;
        background    : #E2E2E2;
    
        @media screen and (min-width : $break-table) {
            height        : 800px;
            margin-top    : -40px;
            margin-bottom : -75px;
            border-radius : 30px 30px 15px 15px;
        }
        @media screen and (min-width : $break-desktop) {
            margin-bottom : -105px;
        }
    }
    
    .intimacy-map__title {
        display    : none;
        visibility : hidden;
        @media screen and (min-width : 851px) {
            display       : block;
            visibility    : visible;
            font-size     : 30px;
            font-weight   : bold;
            font-style    : normal;
            line-height   : 1.1;
            margin-bottom : 25px;
            color         : #1E1E1E;
        }
    }
    .catalog-filter {
        li {
            margin-bottom: 10px;
        }
        .label-text {
            font-size: 20px;
        }
    }

    
    .b__map-text {
        padding : 40px 20px;
        color   : #FFF;
    
        & + .footer-info {
            padding-top : 20px;
        }
    }

    .leaflet-marker-icon {
        margin-left: 0px;
        margin-top: 0px;
        width: 40px;
        height: 40px;
        transition: all 0.3s ease;

        .marker-cluster-icon, .marker-cluster-icon-wrapper {
            border-radius: 50%;
        }

        .marker-cluster-icon-wrapper {
            background-color: rgba(110, 204, 57, .6);
            width: 40px;
            height: 40px;
            text-align: center;
            font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
        }

        .marker-cluster-icon {
            position: relative;
            top: 5px;
            left: 5px;
            background-color: rgba(110, 204, 57, 1);
            width: 30px;
            height: 30px;

            span {
                line-height: 30px;
            }
        }

        &:hover, &:focus {
            transform: scale(1.5);
        }
    }

    .map-i-am-here-tag {
        background-color: #900C3F;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 14px;
        padding: 10px 15px;
        position: relative;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(100% - 1px);
            transform: translate(-50%, 0);
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #900C3F;
        }
    }
}
#photos-container {
    display: flex;
    flex-wrap: wrap;
    img {
        display: flex;
    }
}
main.page-map {
    .button-show-all .main-button {
        background: #C2818D;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        width: inherit;
        text-align: center;
        padding: 0 20px;
        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border: none;
        position: relative;
        font-size: 1.4rem;
        line-height: 1;
        font-weight: 600;
        font-family: Montserrat, sans-serif;
        color: #fff;

        svg {
            margin-left: 10px;

            path {
                fill: white;
            }
        }
    }
}