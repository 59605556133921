.top-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 0;


    .top-filter {
        .select {
            height: 50px;
            border: none;
        }

        &.toggle-filters {
            .select[data-modal] {
                border-radius: 4px;
            }
            @include media-breakpoint-down(md) {
                .show-reset {
                    .select[data-modal] {
                        border-radius: 4px 0 0 4px;
                    }
                }
            }
        }

        .select-group {
            margin-bottom: 0;

            .button-location, .button-search {
                background-color: #C2818D;
                padding: 8px 8px;

                &.reset-filters {
                    display: none;
                }

                .icon svg {
                    height: 35px;
                    width: 35px;

                    path {
                        stroke-width: 1;
                    }
                }

                .text {
                    font-weight: 400;
                    font-size: 15px;
                    padding: 0;
                    line-height: 34px;
                }

                &.loading {
                     background: #b9b9b9;

                    .icon {
                        svg {
                            animation: ldio-gr6w2ya2g2e 1s linear infinite;
                        }
                    }
                }

                .loading-spinner {
                    display: none;
                }
            }

            .toggle-button {
                .icon-right {
                    transition: all 0.3s ease;
                }
                &.opened {
                    .icon-right {
                        transform: rotate(-180deg);
                        path {
                            stroke: #AA5060;
                        }
                    }
                }
            }

            &.show-reset {
                .reset-filters {
                    display: block;
                }
            }
            @include media-breakpoint-up(md) {
                &.show-reset {
                    .reset-filters {
                        display: none;
                    }
                }
            }
        }

        &.filter-city .text,
        &.filter-sub-categories .text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .filter-city {
        width: 356px;

        .select-city {
            width: 100%;
            min-width: 0;

            .icon-left {
                .a {
                    fill: rgb(170, 80, 96);;
                }
            }
        }
        .select {
            padding: 5px 5px 5px 15px;
        }
    }

    .filter-sub-categories {
        width: 246px;
        .select {
            //padding-right: 38px;
        }
        .catalog-filter__dropdown {
            display: block;
            position: relative;
        }
        li {
            margin-bottom: 0;
            &.active {
                background: transparent;
            }
            &:hover {
                background: transparent;
            }
            label {
                display: flex;
                font-size: 17px;
                line-height: 14px;
                color: #3D393A;
                padding: 4px 20px;
                align-items: center;
                cursor: pointer;

                span {
                    color: #3d393a;
                    display: block;
                    font-size: 17px;
                    line-height: 14px;
                    padding: 0;
                    font-weight: 400;
                }
            }
            .jq-checkbox-own {

            }
        }
    }

    .count {
        display: none;
        background-color: #AA5060;
        border-color: #AA5060;
        color: #E7E7E7;
        line-height: 22px;
        border-radius: 15px;
        min-width: 30px;
        justify-content: center;
        padding-top: 3px;
        padding-bottom: 3px;

        &.active {
            display: inline-flex;
        }
    }

    .radius {
        font-size: 10px;

        &:hover {
            cursor: pointer;
            box-shadow: 0 1px 24.65px rgb(0 0 0 / 13%);
            ~ .select { display: none; }
        }

        .small-text {
            font-size: 10px;
        }
        .normal-text {
            white-space: nowrap;
            overflow: hidden;
            font-size: 13px;
            padding-top: 2px;
        }

        .select {
            color: #3d393a;
            display: block;
            font-size: 16px;
            line-height: 26px;
            padding: 2px 9px;
            height: 29px;
            border: none;
            border-radius: 0;
        }
        &.disabled:hover {
            cursor: default;
        }
    }

    .toggle-filters {
        .text {

        }
    }

    &.black {
        .select-city {
            .icon-left {
                .a {
                    fill: #E7E7E7;
                }
            }
        }
        .select[data-modal], .content {
            background: #3d3d3d;
            .text {
                color: #e7e7e7;
                font-family: Montserrat,sans-serif;
                font-size: 1.7rem;
            }
        }
        .button-location, .button-search {
            background-color: #753f5b;
        }
    }
}
