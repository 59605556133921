
@keyframes ldio-gr6w2ya2g2e {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
}
.ldio-gr6w2ya2g2e div {
    position: absolute;
    animation: ldio-gr6w2ya2g2e 1.71s linear infinite;
    width: 88px;
    height: 88px;
    top: 11px;
    left: 11px;
    border-radius: 50%;
    box-shadow: 0 2.2px 0 0 #85a2b6;
    transform-origin: 44px 45.1px;
}
.loadingio-spinner-eclipse-dqdgsc4mhi {
    width: 110px;
    height: 110px;
    display: inline-block;
    overflow: hidden;
    background: transparent;

    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
}
.ldio-gr6w2ya2g2e {
    position: absolute;
    animation: ldio-gr6w2ya2g2e 1s linear infinite;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    top: 2px;
    left: 2px;
    border-radius: 50%;
    box-shadow: 0 1px 0px 0 #e15b64;
    transform-origin: 50% 50%;
}
.ldio-gr6w2ya2g2e div { box-sizing: content-box; }

@keyframes jb {
    0%,
    to {
        opacity: .3;
        transform: scale(1)
    }
    30%,
    70% {
        opacity: .7;
        transform: scale(1.1)
    }
    50% {
        opacity: 1;
        transform: scale(1.2)
    }
}

@keyframes a {
    0%,
    20%,
    50%,
    80%,
    to {
        transform: translateY(0)
    }
    40% {
        transform: translateY(-30px)
    }
    60% {
        transform: translateY(-15px)
    }
}

.bounce {
    animation-name: a
}

@keyframes b {
    0%,
    50%,
    to {
        opacity: 1
    }
    25%,
    75% {
        opacity: 0
    }
}

.flash {
    animation-name: b
}

@keyframes c {
    0% {
        transform: scale(1)
    }
    50% {
        transform: scale(1.1)
    }
    to {
        transform: scale(1)
    }
}

.pulse {
    animation-name: c
}

@keyframes d {
    0%,
    to {
        transform: translateX(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px)
    }
    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px)
    }
}

.shake {
    animation-name: d
}

@keyframes e {
    20% {
        transform: rotate(15deg)
    }
    40% {
        transform: rotate(-10deg)
    }
    60% {
        transform: rotate(5deg)
    }
    80% {
        transform: rotate(-5deg)
    }
    to {
        transform: rotate(0deg)
    }
}

.swing {
    transform-origin: top center;
    animation-name: e
}

@keyframes f {
    0% {
        transform: scale(1)
    }
    10%,
    20% {
        transform: scale(.9) rotate(-3deg)
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale(1.1) rotate(3deg)
    }
    40%,
    60%,
    80% {
        transform: scale(1.1) rotate(-3deg)
    }
    to {
        transform: scale(1) rotate(0)
    }
}

.tada {
    animation-name: f
}

@keyframes g {
    0% {
        transform: translateX(0)
    }
    15% {
        transform: translateX(-25%) rotate(-5deg)
    }
    30% {
        transform: translateX(20%) rotate(3deg)
    }
    45% {
        transform: translateX(-15%) rotate(-3deg)
    }
    60% {
        transform: translateX(10%) rotate(2deg)
    }
    75% {
        transform: translateX(-5%) rotate(-1deg)
    }
    to {
        transform: translateX(0)
    }
}

.wobble {
    animation-name: g
}

@keyframes h {
    0% {
        opacity: 0;
        transform: scale(.3)
    }
    50% {
        opacity: 1;
        transform: scale(1.05)
    }
    70% {
        transform: scale(.9)
    }
    to {
        transform: scale(1)
    }
}

.bounceIn {
    animation-name: h
}

@keyframes i {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    60% {
        opacity: 1;
        transform: translateY(30px)
    }
    80% {
        transform: translateY(-10px)
    }
    to {
        transform: translateY(0)
    }
}

.bounceInDown {
    animation-name: i
}

@keyframes j {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    60% {
        opacity: 1;
        transform: translateX(30px)
    }
    80% {
        transform: translateX(-10px)
    }
    to {
        transform: translateX(0)
    }
}

.bounceInLeft {
    animation-name: j
}

@keyframes k {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    60% {
        opacity: 1;
        transform: translateX(-30px)
    }
    80% {
        transform: translateX(10px)
    }
    to {
        transform: translateX(0)
    }
}

.bounceInRight {
    animation-name: k
}

@keyframes l {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }
    60% {
        opacity: 1;
        transform: translateY(-30px)
    }
    80% {
        transform: translateY(10px)
    }
    to {
        transform: translateY(0)
    }
}

.bounceInUp {
    animation-name: l
}

@keyframes m {
    0% {
        transform: scale(1)
    }
    25% {
        transform: scale(.95)
    }
    50% {
        opacity: 1;
        transform: scale(1.1)
    }
    to {
        opacity: 0;
        transform: scale(.3)
    }
}

.bounceOut {
    animation-name: m
}

@keyframes n {
    0% {
        transform: translateY(0)
    }
    20% {
        opacity: 1;
        transform: translateY(-20px)
    }
    to {
        opacity: 0;
        transform: translateY(2000px)
    }
}

.bounceOutDown {
    animation-name: n
}

@keyframes o {
    0% {
        transform: translateX(0)
    }
    20% {
        opacity: 1;
        transform: translateX(20px)
    }
    to {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

.bounceOutLeft {
    animation-name: o
}

@keyframes p {
    0% {
        transform: translateX(0)
    }
    20% {
        opacity: 1;
        transform: translateX(-20px)
    }
    to {
        opacity: 0;
        transform: translateX(2000px)
    }
}

.bounceOutRight {
    animation-name: p
}

@keyframes q {
    0% {
        transform: translateY(0)
    }
    20% {
        opacity: 1;
        transform: translateY(20px)
    }
    to {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

.bounceOutUp {
    animation-name: q
}

@keyframes r {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.fadeIn {
    animation-name: r
}

@keyframes s {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.fadeInDown {
    animation-name: s
}

@keyframes t {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.fadeInDownBig {
    animation-name: t
}

@keyframes u {
    0% {
        opacity: 0;
        transform: translateX(-20px)
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
}

.fadeInLeft {
    animation-name: u
}

@keyframes v {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
}

.fadeInLeftBig {
    animation-name: v
}

@keyframes w {
    0% {
        opacity: 0;
        transform: translateX(20px)
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
}

.fadeInRight {
    animation-name: w
}

@keyframes x {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    to {
        opacity: 1;
        transform: translateX(0)
    }
}

.fadeInRightBig {
    animation-name: x
}

@keyframes y {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.fadeInUp {
    animation-name: y
}

@keyframes z {
    0% {
        opacity: 0;
        transform: translateY(2000px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

.fadeInUpBig {
    animation-name: z
}

@keyframes A {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

.fadeOut {
    animation-name: A
}

@keyframes B {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(20px)
    }
}

.fadeOutDown {
    animation-name: B
}

@keyframes C {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(2000px)
    }
}

.fadeOutDownBig {
    animation-name: C
}

@keyframes D {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    to {
        opacity: 0;
        transform: translateX(-20px)
    }
}

.fadeOutLeft {
    animation-name: D
}

@keyframes E {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    to {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

.fadeOutLeftBig {
    animation-name: E
}

@keyframes F {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    to {
        opacity: 0;
        transform: translateX(20px)
    }
}

.fadeOutRight {
    animation-name: F
}

@keyframes G {
    0% {
        opacity: 1;
        transform: translateX(0)
    }
    to {
        opacity: 0;
        transform: translateX(2000px)
    }
}

.fadeOutRightBig {
    animation-name: G
}

@keyframes H {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(-20px)
    }
}

.fadeOutUp {
    animation-name: H
}

@keyframes I {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

.fadeOutUpBig {
    animation-name: I
}

@keyframes J {
    0% {
        transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
        animation-timing-function: ease-out
    }
    40% {
        transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
        animation-timing-function: ease-out
    }
    50% {
        transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
        animation-timing-function: ease-in
    }
    80% {
        transform: perspective(400px) translateZ(0) rotateY(1turn) scale(.95);
        animation-timing-function: ease-in
    }
    to {
        transform: perspective(400px) translateZ(0) rotateY(1turn) scale(1);
        animation-timing-function: ease-in
    }
}

.animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    animation-name: J
}

@keyframes K {
    0% {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
    40% {
        transform: perspective(400px) rotateX(-10deg)
    }
    70% {
        transform: perspective(400px) rotateX(10deg)
    }
    to {
        transform: perspective(400px) rotateX(0deg);
        opacity: 1
    }
}

.flipInX {
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
    animation-name: K
}

@keyframes L {
    0% {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0
    }
    40% {
        transform: perspective(400px) rotateY(-10deg)
    }
    70% {
        transform: perspective(400px) rotateY(10deg)
    }
    to {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1
    }
}

.flipInY {
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
    animation-name: L
}

@keyframes M {
    0% {
        transform: perspective(400px) rotateX(0deg);
        opacity: 1
    }
    to {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
}

.flipOutX {
    animation-name: M;
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important
}

@keyframes N {
    0% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1
    }
    to {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0
    }
}

.flipOutY {
    -webkit-backface-visibility: visible!important;
    backface-visibility: visible!important;
    animation-name: N
}

@keyframes O {
    0% {
        transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
    60% {
        transform: translateX(-20%) skewX(30deg);
        opacity: 1
    }
    80% {
        transform: translateX(0) skewX(-15deg);
        opacity: 1
    }
    to {
        transform: translateX(0) skewX(0deg);
        opacity: 1
    }
}

.lightSpeedIn {
    animation-name: O;
    animation-timing-function: ease-out
}

@keyframes P {
    0% {
        transform: translateX(0) skewX(0deg);
        opacity: 1
    }
    to {
        transform: translateX(100%) skewX(-30deg);
        opacity: 0
    }
}

.lightSpeedOut {
    animation-name: P;
    animation-timing-function: ease-in
}

@keyframes Q {
    0% {
        transform-origin: center center;
        transform: rotate(-200deg);
        opacity: 0
    }
    to {
        transform-origin: center center;
        transform: rotate(0);
        opacity: 1
    }
}

.rotateIn {
    animation-name: Q
}

@keyframes R {
    0% {
        transform-origin: left bottom;
        transform: rotate(-90deg);
        opacity: 0
    }
    to {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.rotateInDownLeft {
    animation-name: R
}

@keyframes S {
    0% {
        transform-origin: right bottom;
        transform: rotate(90deg);
        opacity: 0
    }
    to {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.rotateInDownRight {
    animation-name: S
}

@keyframes T {
    0% {
        transform-origin: left bottom;
        transform: rotate(90deg);
        opacity: 0
    }
    to {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.rotateInUpLeft {
    animation-name: T
}

@keyframes U {
    0% {
        transform-origin: right bottom;
        transform: rotate(-90deg);
        opacity: 0
    }
    to {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1
    }
}

.rotateInUpRight {
    animation-name: U
}

@keyframes V {
    0% {
        transform-origin: center center;
        transform: rotate(0);
        opacity: 1
    }
    to {
        transform-origin: center center;
        transform: rotate(200deg);
        opacity: 0
    }
}

.rotateOut {
    animation-name: V
}

@keyframes W {
    0% {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1
    }
    to {
        transform-origin: left bottom;
        transform: rotate(90deg);
        opacity: 0
    }
}

.rotateOutDownLeft {
    animation-name: W
}

@keyframes X {
    0% {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1
    }
    to {
        transform-origin: right bottom;
        transform: rotate(-90deg);
        opacity: 0
    }
}

.rotateOutDownRight {
    animation-name: X
}

@keyframes Y {
    0% {
        transform-origin: left bottom;
        transform: rotate(0);
        opacity: 1
    }
    to {
        transform-origin: left bottom;
        transform: rotate(-90deg);
        opacity: 0
    }
}

.rotateOutUpLeft {
    animation-name: Y
}

@keyframes Z {
    0% {
        transform-origin: right bottom;
        transform: rotate(0);
        opacity: 1
    }
    to {
        transform-origin: right bottom;
        transform: rotate(90deg);
        opacity: 0
    }
}

.rotateOutUpRight {
    animation-name: Z
}

@keyframes ab {
    0% {
        opacity: 0;
        transform: translateY(-2000px)
    }
    to {
        transform: translateY(0)
    }
}

.slideInDown {
    animation-name: ab
}

@keyframes bb {
    0% {
        opacity: 0;
        transform: translateX(-2000px)
    }
    to {
        transform: translateX(0)
    }
}

.slideInLeft {
    animation-name: bb
}

@keyframes cb {
    0% {
        opacity: 0;
        transform: translateX(2000px)
    }
    to {
        transform: translateX(0)
    }
}

.slideInRight {
    animation-name: cb
}

@keyframes db {
    0% {
        transform: translateX(0)
    }
    to {
        opacity: 0;
        transform: translateX(-2000px)
    }
}

.slideOutLeft {
    animation-name: db
}

@keyframes eb {
    0% {
        transform: translateX(0)
    }
    to {
        opacity: 0;
        transform: translateX(2000px)
    }
}

.slideOutRight {
    animation-name: eb
}

@keyframes fb {
    0% {
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(-2000px)
    }
}

.slideOutUp {
    animation-name: fb
}

@keyframes gb {
    0% {
        transform: rotate(0);
        transform-origin: top left;
        animation-timing-function: ease-in-out
    }
    20%,
    60% {
        transform: rotate(80deg);
        transform-origin: top left;
        animation-timing-function: ease-in-out
    }
    40% {
        transform: rotate(60deg);
        transform-origin: top left;
        animation-timing-function: ease-in-out
    }
    80% {
        transform: rotate(60deg) translateY(0);
        opacity: 1;
        transform-origin: top left;
        animation-timing-function: ease-in-out
    }
    to {
        transform: translateY(700px);
        opacity: 0
    }
}

.hinge {
    animation-name: gb
}

@keyframes hb {
    0% {
        opacity: 0;
        transform: translateX(-100%) rotate(-120deg)
    }
    to {
        opacity: 1;
        transform: translateX(0) rotate(0deg)
    }
}

.rollIn {
    animation-name: hb
}

@keyframes ib {
    0% {
        opacity: 1;
        transform: translateX(0) rotate(0deg)
    }
    to {
        opacity: 0;
        transform: translateX(100%) rotate(120deg)
    }
}

.rollOut {
    animation-name: ib
}
