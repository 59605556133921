.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50000;
    background: #e5b8c0;
    color: #000;
    box-shadow: 0 1px 24.65px rgb(0 0 0 / 13%);
    padding: 33px;
    max-width: 416px;
    border-radius: 0 0 4px 4px;
    text-align: center;
    opacity: 0;
    transition: all 200ms;

    &.modal-center {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        padding: 0;
        margin: 0;
        max-width: none;
        opacity: 1;
        background: transparent;

        .modal-wrap {
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            padding: 0 8px;
            box-sizing: border-box;

            &:before {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            .modal-content {
                cursor: auto;
                width: 97%;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin: 0 auto;
                text-align: left;
            }
        }

    }

    &.active {
        opacity: 1;
    }

    .close {
        margin-top: 10px;
        background: #FEFEFE;
        border-radius: 4px;
        color: #C2818D;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        flex-grow: 10;
        text-align: center;
        cursor: pointer;
    }


    &.modal-large {
        .modal-content-wrap {
            max-width: 765px;
            padding: 54px 60px 60px;
            background-color: #ffffff;
            margin: 0 auto;
            position: relative;

            form {
                .error {
                    color: darkred;
                    padding-top: 5px;
                }
            }

            input {
                display: block;
                width: 100%;
                height: 32px;
                padding: 0 10px;
                border: 1px solid #baa67d;
            }

            textarea {
                display: block;
                width: 100%;
                height: 137px;
                padding: 7px 10px;
                border: 1px solid #baa67d;
                resize: none;
                font-size: 1.4rem;
            }


            .modal-title {
                padding-bottom: 18px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
                font-size: 2.4rem;
                color: #000000;
                text-transform: uppercase;
                position: relative;
                margin-top: -10px;
                margin-bottom: 21px;
                text-align: left;

                &:before {
                    content: '';
                    display: block;
                    width: 50px;
                    height: 2px;
                    background-color: #b5a179;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            .mfp-close {
                width: 44px;
                height: 44px;
                line-height: 44px;
                position: absolute;
                right: 0;
                top: 0;
                text-decoration: none;
                text-align: center;
                opacity: .65;
                padding: 0 0 18px 10px;
                color: #333;
                font-style: normal;
                font-size: 28px;
                font-family: Arial, Baskerville, monospace;
            }

            button.mfp-arrow, button.mfp-close {
                overflow: visible;
                cursor: pointer;
                background: transparent;
                border: 0;
                -webkit-appearance: none;
                appearance: none;
                display: block;
                outline: none;
                padding: 0;
                z-index: 1046;
                box-shadow: none;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
            }

        }
        .buttons {
            display: flex;
            justify-content: space-evenly;
        }

        a.button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 220px;
            height: 45px;
            background-color: #900C3F;
            font-weight: normal;
            font-size: 1.6rem;
            color: #ffffff;
            text-align: center;

            &:hover {
                background-color: #cd4d7e;
                color: #ffffff;
            }

            &.cancel {
                background-color: #f4f4f4;
                color: #000;
                &:hover {
                    background-color: #cd4d7e;
                    color: #ffffff;
                }
            }
        }
    }
}
